var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.trip)?_c('div',{staticClass:"upgrade-trip-checkout-confirm-view tw-mb-5"},[_c('v-container',{staticClass:"tw-text-left px-10"},[_c('v-row',[_c('v-col',{staticClass:"mb-8"},[_c('img',{attrs:{"src":require("@/assets/svgs/premium/letsjetty-premium.svg")}})])],1),_c('v-row',[_c('v-col',[_c('h1',{staticClass:"mb-4"},[_vm._v("Flying First Class")]),_c('p',[_vm._v("Trip upgrade credits have been applied to your account.")])])],1),_c('v-divider',{staticClass:"mt-7 mb-10"}),_c('v-row',[_c('v-col',[_c('h2',[_vm._v("Would you like to apply 1 credit to upgrade this trip?")])])],1),_c('v-row',{staticClass:"mb-10"},[_c('v-col',{staticClass:"tw-rounded-lg tw-py-3 tw-px-5 tw-bg-lighter-grey tw-border tw-border-solid tw-border-lightest-grey tw-shadow-custom-sm",attrs:{"cols":"12"}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"3"}},[_c('v-img',{attrs:{"src":_vm.iconUrl(_vm.trip.icon)}})],1),_c('v-col',{staticClass:"text-left",attrs:{"cols":"7"}},[_c('h4',{staticClass:"tw-font-semibold tw-truncate",class:{
                'tw-text-dark-green-two': !_vm.trip.isPremium,
                'tw-text-white': _vm.trip.isPremium
              }},[_vm._v(" "+_vm._s(_vm.trip.title)+" ")]),_c('p',{staticClass:"tw-m-0 tw-text-xs",class:{
                'tw-text-dark-green-two': !_vm.trip.isPremium,
                'tw-text-white': _vm.trip.isPremium
              }},[_vm._v(" Date: "+_vm._s(_vm.getFormattedDateRange(_vm.trip.startDate, _vm.trip.endDate))+" ")])])],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-btn',{staticClass:"j-premium",attrs:{"block":"","depressed":"","x-large":"","rounded":"","dark":"","disabled":_vm.tripUpgradePending,"loading":_vm.tripUpgradePending},nativeOn:{"click":function($event){return _vm.upgradeTrip.apply(null, arguments)}}},[_vm._v(" Yes! Upgrade Trip ")])],1)],1),_c('v-row',[_c('v-btn',{attrs:{"to":{ name: 'TripsRoute' },"block":"","plain":"","link":""}},[_vm._v(" No, Go Back to My Trips ")])],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }