<template>
  <div class="upgrade-trip-checkout-confirm-view tw-mb-5" v-if="trip">
    <v-container class="tw-text-left px-10">
      <v-row>
        <v-col class="mb-8">
          <img src="@/assets/svgs/premium/letsjetty-premium.svg" />
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <h1 class="mb-4">Flying First Class</h1>
          <p>Trip upgrade credits have been applied to your account.</p>
        </v-col>
      </v-row>
      <v-divider class="mt-7 mb-10" />
      <v-row>
        <v-col>
          <h2>Would you like to apply 1 credit to upgrade this trip?</h2>
        </v-col>
      </v-row>

      <v-row class="mb-10">
        <v-col
          class="tw-rounded-lg tw-py-3 tw-px-5 tw-bg-lighter-grey tw-border tw-border-solid tw-border-lightest-grey tw-shadow-custom-sm"
          cols="12"
        >
          <v-row align="center">
            <v-col cols="3">
              <v-img :src="iconUrl(trip.icon)" />
            </v-col>
            <v-col cols="7" class="text-left">
              <h4
                class="tw-font-semibold tw-truncate"
                :class="{
                  'tw-text-dark-green-two': !trip.isPremium,
                  'tw-text-white': trip.isPremium
                }"
              >
                {{ trip.title }}
              </h4>
              <p
                class="tw-m-0 tw-text-xs"
                :class="{
                  'tw-text-dark-green-two': !trip.isPremium,
                  'tw-text-white': trip.isPremium
                }"
              >
                Date: {{ getFormattedDateRange(trip.startDate, trip.endDate) }}
              </p>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-btn
            class="j-premium"
            block
            depressed
            x-large
            rounded
            dark
            @click.native="upgradeTrip"
            :disabled="tripUpgradePending"
            :loading="tripUpgradePending"
          >
            Yes! Upgrade Trip
          </v-btn>
        </v-col>
      </v-row>

      <v-row>
        <v-btn :to="{ name: 'TripsRoute' }" block plain link>
          No, Go Back to My Trips
        </v-btn>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { FormattedDateRange } from "@/mixins/FormattedDateRange.js";
import { DateTime } from "luxon";
export default {
  name: "UpgradeTripCheckoutConfirmView",

  mixins: [FormattedDateRange],

  data() {
    return {
      tripUpgradePending: false,
      placeholderMessage:
        "Hi everyone, join me on Let's Jetty to plan our trip! We can figure out details together but let me know if you're interested."
    };
  },

  computed: {
    sessionUser() {
      return this.$store.state.auth.user;
    },
    wallet() {
      return this.sessionUser.user_wallet;
    },
    trip() {
      return this.$store.state.trip.keyedById[this.$route.params.id];
    }
  },

  methods: {
    iconUrl(icon) {
      return require(`@/assets/svgs/my_trips/${icon}`);
    },

    async upgradeTrip() {
      this.tripUpgradePending = true;
      await this.$store
        .dispatch("trip-premium-upgrade/create", {
          tripId: this.trip.id
        })
        .then(() => {
          if (localStorage.getItem("tripData")) {
            let tripData = JSON.parse(localStorage.getItem("tripData"));
            let activeDesign = localStorage.getItem("template");
            this.createInvitation(tripData, activeDesign);
            localStorage.removeItem("tripData");
            localStorage.removeItem("template");
          } else {
            this.$router.push({
              name: "TripViewRoute",
              params: { id: this.trip.id }
            });
          }
        });
      this.tripUpgradePending = false;
    },

    async createInvitation(tripData, template) {
      tripData.tripId = this.trip.id;
      if (tripData.message.length === 0)
        tripData.message = this.placeholderMessage;
      this.$store
        .dispatch("trip-invitation/create", {
          ...tripData,
          template: template,
          rsvpDeadline: DateTime.fromFormat(
            tripData.rsvpDeadline,
            "yyyy-MM-dd",
            {
              setZone: true
            }
          )
            .endOf("day")
            .toISODate()
        })
        .then((res) => {
          const now = DateTime.now();
          const start = DateTime.fromISO(this.sessionUser.createdAt);
          const diff = start.diff(now, "days").toObject();
          const count = Math.abs(Math.round(diff.days));
          let newUser = false;
          if (count <= 7) {
            newUser = true;
          }

          window.dataLayer.push({
            event: "create_trip_invite",
            uuid: this.sessionUser.uuid,
            signup_date: DateTime.fromISO(this.sessionUser.createdAt).toISO(),
            new_user: newUser,
            verified_user: this.sessionUser.isEmailVerified,
            sign_up_trip_id: this.sessionUser.signupTripId,
            page_location: localStorage.getItem("previous_route"),
            trip_invite_id: res.id,
            response_deadline: res.rsvpDeadline,
            survey_attached:
              this.trip.surveys && this.trip.surveys.length > 0 ? true : false
          });

          this.$router.push({
            name: "CrewListRoute",
            params: { uuid: res.uuid, inviteId: res.id }
          });
        })
        .catch((error) => {
          console.error("Error creating invitation:", error);
        });
    }
  },

  async beforeMount() {
    this.checkoutLoading = true;

    this.$store.commit("meta/setHeader", {
      defaultHeaderDisabled: true
    });

    // Dispatch the action to get trip details
    await this.$store.dispatch("users/get", this.sessionUser.id);
    await this.$store.dispatch("trip/get", this.$route.params.id);
  },
  mounted() {},
  beforeDestroy() {}
};
</script>

<style lang="scss">
.upgrade-trip-checkout-confirm-view {
  h1 {
    font-family: Bebas Neue;
    font-size: 80px;
    font-weight: 400;
    line-height: 80px;
    letter-spacing: -0.01em;
    text-align: left;
  }
  h2 {
    font-family: Figtree;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: -0.02em;
    text-align: left;
  }

  h3 {
    font-family: Figtree;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: -0.02em;
    text-align: left;
  }

  p {
    font-family: Figtree;
    font-size: 20px;
    font-weight: 400;
    text-align: left;
    color: #949494;
  }

  .j-premium-text {
    font-weight: 600;
    color: #1743bb;
  }

  .perks-carousel {
    overflow: visible;

    .v-responsive {
      overflow: visible;
    }

    .v-sheet {
      border-radius: 6px;
      box-shadow: 1px 6px 19px 0px rgba(0, 0, 0, 0.11);
    }

    .v-carousel__controls {
      .v-btn > .v-btn__content .v-icon {
        color: #d9d9d9;
      }

      .v-btn.v-btn--active > .v-btn__content .v-icon {
        color: #585858;
      }
    }
  }
}
</style>
